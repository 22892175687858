<template>
  <div class="login">
    <div class="login__form">
      <el-form :model="form" label-position="top" ref="setUpAccountForm">
        <el-row type="flex">
          <el-col :span="24">
            <div class="login__form-logo">
              <img :src="getImage('eden_logo.svg')" alt="Eden" />
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Enter current password"
              prop="current_pwd"
              :rules="validateField()"
            >
              <el-input
                type="password"
                v-model="form.current_pwd"
                @keyup.enter.native="setUpAccount"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Enter new password"
              prop="new_pwd"
              :rules="validateField()"
            >
              <el-input
                type="password"
                v-model="form.new_pwd"
                @keyup.enter.native="setUpAccount"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Re-enter new password"
              prop="confirm_new_pwd"
              :rules="validateField()"
            >
              <el-input
                type="password"
                v-model="form.confirm_new_pwd"
                @keyup.enter.native="setUpAccount"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="is-flex justify-center">
          <el-button :loading="submitting" type="primary" @click="setUpAccount"
            >Complete setup</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>
  
  <script>
import settings from "../requests/auth";
import * as actions from "../store/action-types";

export default {
  name: "AcceptInvite",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Enter your new password"));
      } else if (value === this.form.current_pwd) {
        callback(new Error("New password can't be old password"));
      } else {
        callback();
      }
    };
    const confirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Re-enter your password"));
      } else if (value !== this.form.new_pwd) {
        callback(new Error("Passwords do not match"));
      } else {
        callback();
      }
    };
    return {
      submitting: false,
      form: {
        current_pwd: "",
        new_pwd: "",
        confirm_new_pwd: "",
      },
      rules: {
        new_pwd: [{ validator: validatePassword, trigger: "blur" }],
        confirm_new_pwd: [{ validator: confirmPassword, trigger: "blur" }],
      },
    };
  },
  methods: {
    setUpAccount() {
      this.$refs.setUpAccountForm.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.changing = true;
        settings
          .acceptInvite(this.$route.params.id, this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.login(response.data.data);
            } else {
              this.$message.error(response.data.message);
              this.changing = false;
            }
          })
          .catch((error) => {
            this.changing = false;
            const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          });
        return true;
      });
    },
    login(email) {
      const payload = {
        email: email,
        password: this.form.new_pwd,
      };

      this.$store
        .dispatch(actions.LOGIN, payload)
        .then((response) => {
          if (response.data.status) {
            this.mixpanelTrack({ event: "btn_login" });
            const role = response.data.data.role;

            if (role === "steward") {
              this.$router.push({ name: "production-provisions.index" });
            } else if (role === "ofa") {
              this.$router.push({ name: "order-fulfillment.index" });
            } else {
              this.$router.push({ name: "customers.index" });
            }
            this.submitting = false;
          } else {
            this.submitting = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.changing = true;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.$router.push({ name: "login" });
        });
    },
  },
};
</script>
  
  <style scoped lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  &__form {
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-logo {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }

    .el-form {
      width: 100%;
    }

    .password-reset {
      margin-top: 20px;
    }
    .link {
      cursor: pointer;
    }
  }
}
</style>
  